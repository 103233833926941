import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 用户信息
    user: {
      name: "",
      userId: "",
      // 账号
      userName: "",
      // 角色，1管理员，2导师，3学生
      role: 1,
      // 角色名字
      roleName: "",
    },
    // 上一个路由信息
    from: {}
  },
  getters: {
    user(state) {
      return state.user;
    },
    color() {
      return {
        tsu: "#804793",
      };
    },
    from(state){
      return state.from;
    }
  },
  mutations: {
    setUser(state, params) {
      state.user = { ...params };
    },
    setFrom(state, params){
      state.from = { ...params };
    }
  },
  actions: {},
});
