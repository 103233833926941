import Vue from 'vue'
import App from '@/App.vue'
import router from './router'
import store from '@/store'
import axios from 'axios'
import Bus from '@/utils/bus'
import Vant from 'vant';
import 'vant/lib/index.css';


Vue.use(Vant,axios,Bus);
axios.defaults.headers.post['Content-Type'] = 'application/x-www-from-urlencoded;charset=utf-8'
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
