import { post } from "@/utils/http";
import Cookies from "js-cookie";

// 拦截器，登录检查，不同设备跳转
export default function (router) {
  router.beforeEach((to, from, next) => {
    if (to.meta.title) {
      //如果设置标题，拦截后设置标题
      document.title = to.meta.title;
    }

    // 如果不需要登录校验 // ignoreLoginCheck 写在当前router的meta中（信息元）
    if (to.meta.ignoreLoginCheck) {
      next();
    } else {
      post("/account/login/check", {})
        .then((res) => {
          if (res.success) {
            // 存上一个路由的信息
            router.app.$store.commit("setFrom", from);
            router.app.$store.commit("setUser", res.data);
            // 已经是登录状态
            if (to.meta.title === "登录") {
              // 登录页
              if (to.query.nextUrl) {
                next({ path: decodeURIComponent(nextUrl) });
              } else {
                // 根据用户角色跳转到不同登陆界面
                if (
                  to.meta.terminal == "admin" &&
                  res.data.roleName == "admin"
                ) {
                  next({ path: "/manager/professor" });
                } else if (
                  to.meta.terminal == "user" &&
                  res.data.roleName != "admin"
                ) {
                  next({ path: `/${res.data.roleName}/home` });
                } else {
                  // 访问端与身份不一致重新登录
                  Cookies.remove("token", { path: "/" });
                  window.location.href = `/${to.meta.terminal}/#/login`;
                  window.location.reload();
                }
              }
            } else {
              next();
            }
          } else {
            // 未登录
            if (to.meta.title === "登录") {
              // 访问的是登录页
              next();
            } else {
              next({
                path: "/login",
                query: {
                  nextUrl: encodeURIComponent(to.fullPath),
                },
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
}
