import Vue from "vue";
import VueRouter from "vue-router";
import interceptor from "@/router/interceptor";

Vue.use(VueRouter);

const routes = [
  // 登录
  {
    path: "/",
    redirect: "/login",
    meta: {
      title: "登录",
      terminal: 'user'
    },
  },
  {
    path: "/login",
    name: 'login',
    component: () => import("../login/Login.vue"),
    meta: {
      title: "登录",
      terminal: 'user'
    },
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../login/ForgetPwd.vue"),
    meta: {
      title: "忘记密码",
      terminal: 'user',
      ignoreLoginCheck: true,
    },
  },
  // 导师
  {
    path: "/professor",
    redirect: "/professor/home",
    component: () => import("../components/MPage.vue"),
    meta: {
      terminal: 'user'
    },
    children: [
      {
        path: "home",
        name: "professorHome",
        component: () => import("../professor/Home.vue"),
        meta: {
          title: "主页",
          terminal: 'user'
        },
      },
      {
        path: "detail",
        name: "professorDetail",
        component: () => import("../professor/Detail.vue"),
        meta: {
          title: "详情",
          terminal: 'user'
        },
      },
      {
        path: "edit",
        name: "professorEdit",
        component: () => import("../professor/Edit.vue"),
        meta: {
          title: "个人信息",
          terminal: 'user'
        },
      },
      {
        path: "list",
        name: "professorList",
        component: () => import("../professor/List.vue"),
        meta: {
          title: "导师墙",
          terminal: 'user'
        },
      },
    ],
  },
  // 学生
  {
    path: "/student",
    redirect: "/student/home",
    component: () => import("../components/MPage.vue"),
    meta: {
      terminal: 'user'
    },
    children: [
      {
        path: "home",
        name: "studentHome",
        component: () => import("../student/Home.vue"),
        meta: {
          title: "主页",
          terminal: 'user'
        },
      },
      {
        path: "edit",
        name: "studentEdit",
        component: () => import("../student/Edit.vue"),
        meta: {
          title: "个人信息",
          terminal: 'user'
        },
      },
      {
        path: "clock",
        name: "studentClock",
        component: () => import("../student/Clock.vue"),
        meta: {
          title: "打卡",
          terminal: 'user'
        },
      },
    ],
  },
  // 活动
  {
    path: "/activity",
    redirect: "/activity/list",
    component: () => import("../components/MPage.vue"),
    meta: {
      terminal: 'user'
    },
    children: [
      {
        path: "list",
        name: "activityList",
        component: () => import("../activity/List.vue"),
        meta: {
          title: "书院活动",
          terminal: 'user'
        },
      },
      {
        path: "detail",
        name: "activityDetail",
        component: () => import("../activity/Detail.vue"),
        meta: {
          title: "活动详情",
          terminal: 'user'
        },
      },
      {
        path: "edit",
        name: "activityEdit",
        component: () => import("../activity/Edit.vue"),
        meta: {
          title: "活动",
          terminal: 'user'
        },
      },
      {
        path: "share",
        name: "activityShare",
        component: () => import("../activity/Share.vue"),
        meta: {
          title: "分享活动",
          terminal: 'user'
        },
      },
    ],
  },
  {
    path: '*',
    meta: {
      terminal: 'user'
    },
    beforeEnter: (to, from, next) => {
      let user = router.app.$store.getters.user;
      // 重新跳转到角色主页
      next(`/${user.roleName}/home`);
    }
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

interceptor(router);

export default router;
