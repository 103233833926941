//封装axios请求
import axios from "axios";
import Cookies from 'js-cookie';

const STATIC_URL = () => {
  if (process.env.NODE_ENV == "development") {
    return process.env.VUE_APP_BASE_URL;
  } else if (process.env.NODE_ENV == "test") {
    return process.env.VUE_APP_BASE_URL;
  } else {
    return process.env.VUE_APP_BASE_URL;
  }
};

export const staticURL = STATIC_URL();

const headerParams = {};

const instance = axios.create({
  timeout: 10000,
  baseURL: process.env.NODE_ENV == "development" ? "/api" : "/api",
  headers: {
    "Content-Type": "application/json; charset=UTF-8;",
    ...headerParams,
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    if (Cookies.get('token')) {
      config.headers["token"] = Cookies.get('token');
    }

    return config;
  },
  (err) => Promise.reject(err)
);

instance.interceptors.response.use(
  (res) => res.data,
  (err) => Promise.reject(err)
);

export const get = (url, data, config = {}) => instance.get(url, data, config);
export const post = (url, data, config = {}) =>
  instance.post(url, data, config);
export const dlt = (url, data, config = {}) =>
  instance.delete(url, data, config);
