<template>
  <div id="app">
    <keep-alive exclude="flowchart,empathy,login,project,list,denied">
      <router-view :key="$route.fullPath"/>
    </keep-alive>
  </div>
</template>
<script>

export default {
}
</script>
<style>
body{
  margin:0;
}
</style>
